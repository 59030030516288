import React from "react";


function BlogMasterAdd() {





    return (

        <>

            <section className="blog section-padding" data-scroll-index="5">





                <div className="container">
                    <div className="row">

                        <div className="section-head col-sm-12">
                            <h4>
                                <span>Blog</span><br />
                                Ekle
                            </h4>
                        </div>



                    </div>
                    <br />
                    <div>

                        <form>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Başlık</label>
                                <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                    placeholder="Başlık" />

                            </div>
                            <div className="form-group">
                                <label for="exampleInputPassword1">Detay</label>
                                <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Detay" />
                            </div>


                            <div class="form-group">
                                <label for="exampleFormControlFile1">Küçük Png 600 x 400</label>
                                <input type="file" class="form-control-file" id="exampleFormControlFile1" />
                            </div>


                            <div class="form-group">
                                <label for="exampleFormControlFile2">Büyük Png 1920 x 900</label>
                                <input type="file" class="form-control-file" id="exampleFormControlFile1" />
                            </div>


                            <div className="form-group">
                                <label for="exampleInputPassword1">Alt Etiketi</label>
                                <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Alt Etiketi" />
                            </div>

                            
                            <button type="submit" className="btn btn-primary">Kaydet</button>
                        </form>
                    </div>
                </div>



            </section>



        </>

    )
}


export default BlogMasterAdd;