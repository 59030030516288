import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Contact from "./Contact"


function Blogs ()
{

const [blogs, setBlog] = useState( []);
useEffect(
    () => {
        axios.get('/blogs/GetAllBlogs', {
            headers: {

                'Access-Control-Allow-Origin': '*',
            }


        })
            .then(res => {

                setBlog(res.data)
                console.log(res.data)


            }).catch(err => {
                console.log(err)
            })


    },[]);






    return (


        <>
        <Navbar />
        <Header/>
        <section className="blog section-padding" data-scroll-index="5">
          <div className="container">
          
          {blogs.map((blog) => (

                         
<div key={blog.id} className="col-lg-4">
    <div className="item text-center mb-md50">
        <div className="post-img">
            <img src={blog.photo} alt={blog.alttag} />
            <div className="date">
                <a href={'blogs/'+(blog.id)} >

                   
                    <span>{new Intl.DateTimeFormat("tr-TR",{
                        year:"numeric",
                        month:"long",
                        day:"2-digit"


                    }).format(blog.releaseTime)}</span>
                    
                </a>
            </div>
        </div>
        <div className="content">
            <h5><a href={'blogs/'+(blog.id)}>{blog.header}</a></h5>
            <p>{blog.detail}</p>
           
        </div>
    </div>
</div>





))}
             
          </div>

             

        </section>

        
        <Contact/>
        <Footer/>

        </>


)
    



}



export default Blogs;