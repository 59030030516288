import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";




function Blog() {






    const [blogs, setBlog] = useState( []);
    useEffect(
        () => {
            axios.get('/blogs/GetlLastBlogs', {
                headers: {

                    'Access-Control-Allow-Origin': '*',
                }


            })
                .then(res => {

                    setBlog(res.data)
                    console.log(res.data)


                
                }).catch(err => {
                    console.log(err)
                })


        },[]);




    return (


        <section className="blog section-padding" data-scroll-index="5">





            <div className="container">
                <div className="row">

                    <div className="section-head col-sm-12">
                        <h4>
                            <span>Son</span><br />
                            Bloglarımız
                        </h4>
                    </div>

                    {blogs.map((blog) => (

                         
                        <div key={blog.id} className="col-lg-4">
                            <div className="item text-center mb-md50">
                                <div className="post-img">
                                    <img src={blog.photo} alt={blog.AltTag} />
                                    <div className="date">
                                        <a href={'blogs/'+(blog.id)}>

                                           
                                            <span>{new Intl.DateTimeFormat("tr-TR",{
                                                year:"numeric",
                                                month:"long",
                                                day:"2-digit"


                                            }).format(blog.releaseTime)}</span>
                                            
                                        </a>
                                    </div>
                                </div>
                                <div className="content">
                                    <h5><a href={'blogs/'+(blog.id)}>{blog.header}</a></h5>
                                    <p>{blog.detail}</p>
                                   
                                </div>
                            </div>
                        </div>





                    ))}

                </div>
                <br />
                <div>
                    <a style={{ color: '#1AA7EC' }} href="blogs" className="link-primary">
                        <h5>Tüm Blogları Gör</h5>
                    </a>
                </div>
            </div>



        </section>

    )


}
export default Blog;


