import React, { useEffect, useState } from "react";
import Contact from "./Contact";
import Footer from "./Footer";
import axios from "axios";
import Navbar from "./Navbar";
import { useParams } from "react-router-dom";



function BlogDetail() {
const params = useParams();


    const [blog, setBlog] = useState('');
    useEffect(
        () => {

          
            axios.post('/blogs/GetBlogWithId', {
                Id: params.id

                    

            })
                .then(res => {
                    
                    setBlog(res.data)



                }).catch(err => {
                    console.log(err)
                })


        }, []);


    return (



        <>
            <Navbar />

            <header className="header valign bg-img" data-scroll-index="0" data-overlay-dark="5" data-background={blog.photo} data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="row">
                        <div className="full-width text-center caption mt-30">
                            <h2 >{blog.header}</h2>


                            <p>{blog.detail}</p>


                        </div>
                    </div>
                </div>

                <div className="arrow">
                    <a href="#" data-scroll-nav="1">
                        <i className="fas fa-chevron-down"></i>
                    </a>
                </div>
            </header>
            
            <section className="hero section-padding pb-0" data-scroll-index="1">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-2 col-lg-8 text-center mb-80">
                        <h4 className="extra-text"><span>{blog.header}</span> </h4>
                        <p>{blog.detail}</p>
                    </div>

                    <div className="col-lg-12">
                        <div className="mission">

                           <br></br>

                            <p>
                                {blog.content}
                            </p>
                  
                          

                        </div>
                    </div>

                    

                </div>
            </div>

             </section>

            <Contact />
            <Footer />

        </>

    )

}
export default BlogDetail

