import React from "react";
import Header from "./Header";
import Navbar from "./Navbar"
import Footer from "./Footer";
import Hero from "./Hero";
import Services from "./Services"
import Blog from "./Blog";
import Contact from "./Contact"

class Home extends React.Component {

    render() {

           
  
        return (


            <>
                <Navbar />
                <Header />
                <Hero />
                <Services />
                <Blog />
                <Contact />
                <Footer />






            </>
        )


    }


}

export default Home;