import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";


function BlogMasterDetail(){

    const params = useParams();

    const [blog, setBlog] = useState('');
    useEffect(
        () => {

          
            axios.post('/blogs/GetBlogWithId', {
                Id: params.id

                    

            })
                .then(res => {
                    
                    setBlog(res.data)



                }).catch(err => {
                    console.log(err)
                })


        }, []);

console.log(blog);
    return(

<>


</>

    )
}


export default BlogMasterDetail;