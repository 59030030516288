import React from "react";




class Hero extends React.Component {

    render() {

        return (
            <section className="hero section-padding pb-0" data-scroll-index="1">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-2 col-lg-8 text-center mb-80">
                        <h4 className="extra-text">Nedir bu <span>IT Danışmanlığı</span> hizmetleri ?</h4>
                        <p>IT danışmanlığı, bilgi teknolojileri hizmetlerini kendi bünyenizde personel bulundurma ihtiyacınız olmadan uzman kadromuz tarafından desteklenmesidir.</p>
                    </div>

                    <div className="col-lg-7">
                        <div className="mission">

                            <div className="section-head mb-30">
                                <h4><span>Bizim</span><br/>
                                    Misyonumuz
                                </h4>
                            </div>

                            <p>IT danışmanlığı sağladığımız müşterilerimizin tüm teknolojik faliyetlerini gerçekleştirmek bizim en önemli amacımızdır.
                                Bu ürünler web sitesi tasarlanması, yönetim sistemleri yerleştririlmesi, yapısal kablolalama gibi temel ihtiyaçların yanısıra 
                                dijital pazarlama, otonom sistemler, personel eğitimi gibi daha ileri planlama ve efor isteyen danışmanlıklardır.
                            </p>
                            <p> Kısacası teknoloji, pazarlama, otomasyon, eğitim adına aklınıza gelecek tüm konularda yanınızdayız </p>

                          

                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="skills">

                            <div className="prog-item">
                                <p>Markalaştırma</p>
                                <div className="skills-progress"><span data-value='94%'></span></div>
                            </div>
                            <div className="prog-item">
                                <p>Web Tasarım</p>
                                <div className="skills-progress"><span data-value='95%'></span></div>
                            </div>
                            <div className="prog-item">
                                <p>Otomasyon</p>
                                <div className="skills-progress"><span data-value='92%'></span></div>
                            </div>
                            <div className="prog-item">
                                <p>Yazılım</p>
                                <div className="skills-progress"><span data-value='90%'></span></div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="feat bg-gray pt-80 pb-50 mt-80">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-4">
                            <div className="item">
                                <span className="icon"><i className="icofont icofont-brand-copy"></i></span>
                                <h6>Markalaştırma</h6>
                                <p>Dijital dünyada var olmanız için gereken internet ortamı ihtiyaçlarınızı ve sosyal medya hesaplarınızın oluşturulması ve yönetilmesi. </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="item">
                                <span className="icon"><i className="icofont icofont-chart-pie-alt"></i></span>
                                <h6>Yazılım</h6>
                                <p>Verimliliğin arttırılması, iş takibi, otomasyonlar ve bunlara benzeyen gereksinimler için günümüzünde olmazsa olmaz sistemlerinin geliştirilmesi. </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="item">
                                <span className="icon"><i className="icofont icofont-chart-histogram-alt"></i></span>
                                <h6>Dönüşüm</h6>
                                <p> Gerekli altyapısal çözümler ile işletmenizin değişen ve dijitalleşen dünyaya ayak uydurması için gerekn tüm planlama, tedarik ve eğitimdir. </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

            
        )


    }


}

export default Hero;