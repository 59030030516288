import React from "react";





class Services extends React.Component {

    render() {

        return (

            <section className="services section-padding pb-70" data-scroll-index="3">
            <div className="container">
                <div className="row">
                    
                    <div className="section-head col-sm-12">
                        <h4>
                            <span>Sunduğumuz</span><br/>
                           Hizmetler
                        </h4>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="item">
                            <span className="icon"><i className="icofont icofont-laptop"></i></span>
                            <h6>Altyapı</h6>
                            <p>Firma içerisinde network kablolaması, network cihazlarının yerleştirilmesi gibi olabileceği gibi endüstriyel cihazların otomasyonlara entegrasyonuna kadar tüm süreçlerde hizmet veriyoruz. </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="item">
                            <span className="icon"><i className="icofont icofont-globe-alt"></i></span>
                            <h6>Tanınırlık</h6>
                            <p>Markanızın internet ortamında tanınması için gereken tüm web sitesi, mobil uygulama gibi yazılımların yanısıra, reklam meteryallerinden olan katalog, tanıtım malzemelerine de destek veriyoruz. </p>
                       </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="item">
                            <span className="icon"><i className="icofont icofont-rocket-alt-2"></i></span>
                            <h6>Esnetilebilirlik</h6>
                            <p>Her ölçekte kullanıcılarımıza göre çözümler sunuyoruz. Ofis ortamlarından fabrikalara kadar danışmanlıklarımız esnetilebilir ve uygulanabilirdir.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="item">
                            <span className="icon"><i className="icofont icofont-image"></i></span>
                            <h6>Dijitallleşme</h6>
                            <p>Modern dünyanın standartlarına göre firmanızda akla gelecek tüm dijital dönüşüm operasyonlarını uzmanlarımız yerinde veya uzaktan gerçekleştirebilir. </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="item">
                            <span className="icon"><i className="icofont icofont-samsung-galaxy"></i></span>
                            <h6>Yazılım Geliştirme</h6>
                            <p>Firma içi yönetim sistemleri, otomasyon, kartlı sistemler, özel tasarım yazılım geliştirme hizmetimiz ile daha verimli çalışmanızı sağlıyoruz. </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="item">
                            <span className="icon"><i className="icofont icofont-ui-settings"></i></span>
                            <h6>Kullanıcı Deneyimi</h6>
                            <p>7/24 çalışma mantığımız ile operasyonel devamlılığın sağlanması, dijitil tüm ihtiyaçların profesyonel kadromuz ile karşılanmasıyle üst düzel bir deneyim sunuyoruz. </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="item">
                            <span className="icon"><i className="icofont icofont-dashboard-web"></i></span>
                            <h6>Domain Tescil</h6>
                            <p>Alt kuruluşumuz olan <a style={{color:'#90ee90'}} href="https://kargahosting.com"> kargahosting.com</a>'dan istediğiniz isimi tescil ettirebilirsiniz. Uzmanlarımız sizin için en uygun isimi seçmede yardımcı olacaklardır. </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="item">
                            <span className="icon"><i className="icofont icofont-database"></i></span>
                            <h6>Hosting </h6>
                            <p>Alt kuruluşumuz olan <a style={{color:'#90ee90'}} href="https://kargahosting.com"> kargahosting.com</a>'dan ihtiyacınız kadar alan alabilir web sitenizi barındırabilirsiniz. Ekibimiz bu alanı yönetmenizde ve güvenliğinin sağlanmasında destek vermektedir.  </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="item">
                            <span className="icon"><i className="icofont icofont-server"></i></span>
                            <h6>Sanal Sunucu</h6>
                            <p>Alt kuruluşumuz olan <a style={{color:'#90ee90'}} href="https://kargahosting.com"> kargahosting.com</a>'dan profesyonel kullanım için sanal sunucu alabilir, birden fazla web sitesi, uygulama, yedekleme ihtiyaçlarınızı kesintisiz karşılayabilirsiniz. </p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        )


    }


}

export default Services;