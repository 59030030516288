import {
    BrowserRouter,
    Routes,
    Route
    
  } from 'react-router-dom';
import BlogDetail from './components/BlogDetail';
import Blogs from './components/Blogs';

import Home from './components/Home';
import MasterPage from './components/Manage/MasterPage';
import BlogMasterAdd from './components/Manage/BlogMasterAdd';
import BlogMasterDetail from './components/Manage/BlogMasterDetail';


const App = ()=>{

return (

<BrowserRouter>

<Routes>
<Route path='/' element={<Home />}/>
<Route path='home' element={<Home/>}/>
<Route path='blogs' element={<Blogs/>}/>
<Route path='blogs/:id' element={<BlogDetail/>}/>
<Route path='Master' element={<MasterPage/>}/>
<Route path='Master/BlogMasterAdd' element={<BlogMasterAdd/>}/>
<Route path='Master/BlogMasterDetail/:id' element={<BlogMasterDetail/>}/>



</Routes>
</BrowserRouter>


)

}

export default App;