import React from "react";





class Contact extends React.Component {

    render() {

        return (


            <>
                <section className="contact" data-scroll-index="6">
            <div className="container">
                <div className="row">
                  
                    <div className="col-lg-12 contact-form">
                        <form className="form" id="contact-form" >

                            <div className="messages"></div>

                            <div className="controls">

                                <div className="row">

                                    <div className="section-head col-sm-12">
                                        <h4>
                                            <span>Bizimle</span><br/>
                                            İletişime Geçin
                                        </h4>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input id="form_name" type="text" name="name" placeholder="İsminiz" required="required"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input id="form_email" type="email" name="email" placeholder="Email" required="required"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input id="form_subject" type="text" name="subject" placeholder="Konu"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea id="form_message" name="message" placeholder="Mesaj" rows="4" required="required"></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-12 text-center">
                                        <button type="submit"><span>Gönder</span></button>
                                    </div>

                                </div>                             
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

                <section className="information bg-img" data-overlay-dark="9" data-scroll-index="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="info">
                                    <div className="item">
                                        <span className="icon"><i className="icofont icofont-phone"></i></span>
                                        <div className="cont">
                                            <h6>Telefon : </h6>
                                            <p><br /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="info">
                                    <div className="item">
                                        <span className="icon"><i className="icofont icofont-map"></i></span>
                                        <div className="cont">
                                            <h6>Adres : </h6>
                                            <p>Tepe Prime A Blok No : 18<br />Çankaya / ANKARA</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="info">
                                    <div className="item">
                                        <span className="icon"><i className="icofont icofont-email"></i></span>
                                        <div className="cont">
                                            <h6>Email : </h6>
                                            <p>info@elysiontech.net</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </>


        )


    }


}

export default Contact;