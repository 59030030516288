import React from "react";




class Header extends React.Component {

    render() {

        return (
            
            <header className="header valign bg-img" data-scroll-index="0" data-overlay-dark="5" data-background="../img/bg.jpg" data-stellar-background-ratio="0.5">
    		<div className="container">
    			<div className="row">
    				<div className="full-width text-center caption mt-30">
    					<h2 >ElysionTech IT Danışmanlık Hizmetleri</h2>
                     
                        <h1 className="cd-headline clip">
                        <span className="cd-words-wrapper">
                              <b className="is-visible"></b>
                              <b>BÜYÜTMEK İÇİN  </b>
                              <b>YÖNETMEK İÇİN</b>
                              <b>YENİLEMEK İÇİN</b>
                            </span>
                            <span className="blc"> TEKNOLOJİ </span>
                           
                        </h1>
                        <p>ElysionTech It Danışmanlık olarak, günümüz teknolojileri ile firmanız için gereken tüm teknoloji çözümlerimizle yanınızdayız.</p>
                        <p>Web sitenizden otonom sistemlerinize kadar tüm IT süreçlerinizde sizlerleyiz.</p>
                        <a href="#0"  data-scroll-nav="1" className="butn butn-bord">
                            <span>Daha Fazla Öğren</span>
                        </a>
                        <a href="#0" data-scroll-nav="6" className="butn butn-bg">
                            <span>Sizi Arayalım</span>
                        </a>
    				</div>
    			</div>
    		</div>

    		<div className="arrow">
				<a href="#" data-scroll-nav="1">
					<i className="fas fa-chevron-down"></i>
				</a>
			</div>
    	</header>


            
        )


    }


}

export default Header;