import React from "react";




class Footer extends React.Component {

    render() {

        return (

            <footer className="text-center">
            <div className="container">

                
                <a className="logo" href="#">
                    <img src="../img/logo-light.png" alt="logo"/>          
                </a>
                
                <div className="social">
                   
                    <a href="#0"><i className="icofont icofont-social-instagram"></i></a>
                    <a href="#0"><i className="icofont icofont-brand-linkedin"></i></a>
                  
                </div>

                <p>&copy; 2019 Elysiontech Sanallaştırma Hizmetleri</p>

            </div>
        </footer>
    
            
        )


    }


}

export default Footer;