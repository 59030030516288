import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";



function MasterPage(){


    const [blogs, setBlog] = useState( []);
    useEffect(
        () => {
            axios.get('/blogs/GetlLastBlogs', {
                headers: {

                    'Access-Control-Allow-Origin': '*',
                }


            })
                .then(res => {

                    setBlog(res.data)
                    console.log(res.data)


                
                }).catch(err => {
                    console.log(err)
                })


        },[]);




return(

    <section className="blog section-padding" data-scroll-index="5">







    <div className="container">
        <div className="row">

            <div className="section-head col-sm-12">
                <h4>
                    <span>Blog</span><br />
                    Yönetimi
                </h4>
                <br/>

                <h6>
                   <a href="Master/BlogAdd"><span>Yeni Ekle</span></a> 
                </h6>
                
            </div>
            <div>

                
            </div>

            {blogs.map((blog) => (

                 
                <div key={blog.id} className="col-lg-4">
                    <div className="item text-center mb-md50">
                        <div className="post-img">
                            <img src={blog.photo} alt={blog.AltTag} />
                            <div className="date">
                                <a href={'master/blogmasterdetail/'+(blog.id)}>

                                   
                                    <span>{new Intl.DateTimeFormat("tr-TR",{
                                        year:"numeric",
                                        month:"long",
                                        day:"2-digit"


                                    }).format(blog.releaseTime)}</span>
                                    
                                </a>
                            </div>
                        </div>
                        <div className="content">
                            <h5><a href={'master/blogmasterdetail/'+(blog.id)}>{blog.header}</a></h5>
                            <p>{blog.detail}</p>
                           
                        </div>
                    </div>
                </div>





            ))}

        </div>
        <br />
        <div>
            
        </div>
    </div>



</section>



)

}

export default MasterPage;